import React, { memo, useEffect, useRef, useState } from "react";
import { GridCellParams, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CatalogEnum,
  ContentStatusEnum,
  ProductLiveStatusEnum,
  RolesEnum,
  TabsEnum,
} from "../../../../utils/constants/enums";
import { URL_PRODUCT_CATALOG } from "../../../../routes/routes-path";
import { Product } from "../../../../apis/types/generate-product";
import { copyAsinByRightClick, navigatePage } from "../../../../utils/helpers/common";
import KeywordsPageDialog from "../../../mui/dialogebox/keywordsPageDialoge";
import { useDispatch, useSelector } from "react-redux";
import { getOpportunityReport } from "../../../../store/product/product.actions";
import { isExportButton, showUserEmailInProductsPage } from "../../../../utils/helpers/priviligesChecks";
import {
  OpportunityProductsSelector,
  TotalOpportunityProductSelector,
  UserProductTagsSelector,
} from "../../../../store/product/product.selector";
import { DbUserSelector, UserRoleSelector } from "../../../../store/user/user.selector";
import classes from "./OpportunityGrid.module.css";
import MTypography from "../../../Atoms/MTypography";
import ProductTable from "../../SavedProducts/ProductDataGrid/ProductTable";
import { debounce } from "lodash";

import { usePagination } from "../../../../hooks/usePagination";
import MTextField from "../../../Atoms/MTextField";
import { defaultImage } from "../../../../utils/constants/general-constants";
import { errorHandler, handleAuthError } from "../../../../utils/helpers/apis";
import GridSkeleton from "../../SavedProducts/GridSkeleton/GridSkeleton";

// import { downloadOpportunityReportCSV } from "../../../../pages/SavedProducts/config";
import ProductStatus from "../../../Molecules/ProductStatus";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { getAllTagsOfProduct } from "../../SavedProducts/TagPopper/config";
import MuiTooltip from "../../../Atoms/MuiTooltip";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { generatingProcessTimeTenToFifteen } from "../../../../utils/constants/common";
import FilterListIcon from "@mui/icons-material/FilterList";
import OpportunityGridFilterMenu from "../OpportunityFilterMenu/OpportunityFilterMenu";
import {
  filterMarks,
  manageKeywordCoverageRatio,
  manageLastContentLive,
  manageSearchIncrease,
  manageVolumeChange,
} from "./config";
import { onImgErrorHandler, reduceImgSize } from "../../../../utils/helpers/general-helpers";
import {
  setOpportunityProductLoadingDispatch,
  setOpportunityReportFilter,
  setOpportunitySearchFilterDispatch,
  setOpportunitySearchResetDispatch,
  setOpportunitySorting,
} from "../../../../store/opportunity-report/opportunityReport.actions";
import {
  OpportunityReportFilter,
  OpportunitySearchFilterSelector,
  OpportunityTableSorting,
} from "../../../../store/opportunity-report/opportunityReport.selector";
import { generateProductApis } from "../../../../apis/generate-product";
import { IconButton, Tooltip } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { CompanyRoleSelector } from "../../../../store/company/company.selector";
import { SUCC_CSV_EXPORT } from "../../../../utils/constants/messages/success";

type ProductDataGridState = {
  loading: boolean;
  openDialogBox: boolean;
  openDeleteConfirm: boolean;
  currentDeleteId: string;
};
type OpportunityGridProps = {
  tab: TabsEnum;
  isRerun?: boolean;
  isChildComponent?: boolean;
};

const OpportunityGrid = ({ tab, isChildComponent }: OpportunityGridProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const tags = useSelector(UserProductTagsSelector);
  const dbUser = useSelector(DbUserSelector);
  const userRole = useSelector(UserRoleSelector);
  const products = useSelector(OpportunityProductsSelector);
  const companyRole = useSelector(CompanyRoleSelector);
  const totalProducts = useSelector(TotalOpportunityProductSelector);
  const gridSort = useSelector(OpportunityTableSorting);
  const searchQuery = useSelector(OpportunitySearchFilterSelector);

  const brand = location?.state?.productBrand;
  const [state, setState] = useState<ProductDataGridState>({
    loading: true,
    openDialogBox: false,
    openDeleteConfirm: false,
    currentDeleteId: "",
  });
  const [selectedRows, setSelectedRows] = useState<Product[]>([]);
  const [search, setSearch] = useState<string>(searchQuery);
  // const [searchQuery, setSearchQuery] = useState<string>("");
  const { pageSize, setPageNumber, pageNumber, setPageSize, offset, sortOrder, sortBy, setSortModel } =
    usePagination(100);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [showToolbarOptions, setShowToolbarOptions] = useState<boolean>(false);

  // const [productLiveFilter, setProductLiveFilter] = useState<any>(contentFilters.all);
  const opportunityFilters = useSelector(OpportunityReportFilter);

  const [csvLoading, setCsvLoading] = useState<boolean>(false);

  // for filter
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleCsvExport = async () => {
    try {
      if (!csvLoading) {
        setCsvLoading(true);
        await generateProductApis
          .getOpportunityReportDownloadUrl(getFilters())
          .then(() => {
            toast.success(SUCC_CSV_EXPORT);
            setCsvLoading(false);
          })
          .catch((error) => {
            setCsvLoading(false);
            throw new Error(handleAuthError(error));
          });
      } else {
        toast.warn("Exporting Data. Please Wait");
      }
    } catch (e: any) {
      setCsvLoading(false);
      toast.error(handleAuthError(e));
    }
  };
  const debouncedSearch = useRef(
    debounce((value: string) => {
      if (value.length > 0) {
        dispatch(
          setOpportunityReportFilter({
            lastLiveProductsFilter: false,
            keywordCoverageFilter: false,
            exceedLimitFilter: 0,
          })
        );
      }
      // setSearchQuery(value);
      dispatch(setOpportunitySearchFilterDispatch(value));
    }, 1500)
  ).current;

  const getProducts = async ({ filters, navigate }: any) => {
    dispatch(setOpportunityProductLoadingDispatch(true));
    setState({ ...state, loading: true });
    dispatch(
      getOpportunityReport({
        filters,
        navigate,
      })
    )
      .then(() => {
        setState({ ...state, loading: false });
        dispatch(setOpportunityProductLoadingDispatch(false));
      })
      .catch((e: any) => {
        toast.error(errorHandler(e));
        setState({ ...state, loading: false });
        dispatch(setOpportunityProductLoadingDispatch(false));
      });
  };
  const getFilters = (): any => {
    let filters: any = {
      offset,
      limit: pageSize,
      searchQuery: searchQuery,
      // new entries
      // isReviewPage: tab === TabsEnum.REVIEW,
      sortBy: gridSort.sortBy || "volumeChange",
      hideKeywordCoverageRatioGreaterThanEqualTo: opportunityFilters?.exceedLimitFilter,
      hideLastLiveProducts: opportunityFilters?.lastLiveProductsFilter,
      sortOrder: gridSort.sortOrder || -1,
    };
    if (sortBy !== "") {
      dispatch(
        setOpportunitySorting({
          sortBy,
          sortOrder,
        })
      );
      filters = {
        ...filters,
        sortBy,
        sortOrder,
      };
    }
    if (searchQuery) {
      filters = {
        ...filters,
        searchQuery,
      };
    }

    if (opportunityFilters?.lastLiveProductsFilter) {
      filters = {
        ...filters,
        hideLastLiveProducts: opportunityFilters?.lastLiveProductsFilter,
      };
    }

    if (!opportunityFilters?.keywordCoverageFilter) {
      filters = {
        ...filters,
        hideKeywordCoverageRatioGreaterThanEqualTo: 0,
      };
    }

    if (brand) {
      filters = {
        ...filters,
        productBrand: brand === "Others" ? undefined : brand,
      };
    }

    return filters;
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    return () => {
      dispatch(setOpportunitySearchResetDispatch());
    };
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);
  useEffect(() => {
    if (selectedRows?.length) setShowToolbarOptions(true);
    else setShowToolbarOptions(false);
  }, [selectedRows]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const filters = getFilters();
    getProducts({ filters, navigate });
  }, [
    pageNumber,
    pageSize,
    offset,
    sortBy,
    sortOrder,
    searchQuery,
    opportunityFilters?.lastLiveProductsFilter,
    opportunityFilters?.keywordCoverageFilter,
    opportunityFilters?.exceedLimitFilter,
  ]);

  const handleCellClick = async (gridCellParams: GridCellParams) => {
    if (gridCellParams.field === "productASIN" && !state.loading) {
      if (gridCellParams.row?.status === ContentStatusEnum.GENERATING) {
        setState({ ...state, openDialogBox: true });
        setTimeout(() => {
          setState({ ...state, openDialogBox: false });
        }, 5000);
      } else if (tab === TabsEnum.REVIEW)
        navigatePage(
          `${URL_PRODUCT_CATALOG}/${CatalogEnum.REVIEW_PAGE}/${gridCellParams.row?.productASIN}/${gridCellParams.row?.userID}`,
          navigate
        );
      else {
        navigatePage(
          `${URL_PRODUCT_CATALOG}/${CatalogEnum.DETAIL_PAGE}/${gridCellParams.row?.productASIN}/${gridCellParams.row?.userID}`,
          navigate,
          { state: { isChildComponent: TabsEnum.OPPORTUNITY_REPORT, productBrand: brand } }
        );
      }
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };
  const onSelectedRows = (e: GridSelectionModel) => {
    const selectedIDs = new Set(e);
    setSelectedRows(products.filter((row: any) => selectedIDs.has(row.id)));
    setSelectionModel(e);
  };

  const handleFilterChange = (hideLastLive: boolean, hideKeywordCoverage: boolean, exceedLimit: number) => {
    try {
      dispatch(
        setOpportunityReportFilter({
          lastLiveProductsFilter: hideLastLive,
          keywordCoverageFilter: hideKeywordCoverage,
          exceedLimitFilter: !exceedLimit ? 0 : exceedLimit,
        })
      );
    } catch (e: any) {
      toast.error(e);
    }
  };

  const handleFilterClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setShowFilterMenu(!showFilterMenu);
    setAnchorEl(e.currentTarget);
  };
  const handleFilterClose = () => {
    setShowFilterMenu(false);
    setAnchorEl(null);
  };

  const productsColumns: GridColDef[] = [];
  productsColumns.push({
    field: "productASIN",
    headerName: "ASIN",
    width: 160,
    sortingOrder: ["desc", "asc"],
    renderCell: (params: GridCellParams<Product>) => (
      <div className="d-flex justify-content-between align-items-center w-100">
        <div
          style={{
            pointerEvents: "all",
            cursor: state.loading ? "not-allowed" : "pointer",
          }}
          onClick={() => handleCellClick(params)}
          onContextMenu={(e) => copyAsinByRightClick(e, params.row.productASIN)}
        >
          <div className={"d-flex align-items-center"}>
            <div className={`${classes.ASIN} HoverEffectForLinks`}>{params.row.productASIN}</div>
          </div>
        </div>
        <div className="" style={{ cursor: "pointer", zIndex: 200 }}>
          {!state.loading && params.row?.status !== ContentStatusEnum.GENERATING && (
            <Tooltip title={"Open in new tab"} placement="top">
              <Link
                to={`${URL_PRODUCT_CATALOG}/${CatalogEnum.DETAIL_PAGE}/${params.row?.productASIN}/${params.row?.userID}`}
                target="_blank"
              >
                <IconButton>
                  <ArrowOutwardIcon htmlColor="#2e2e2e" fontSize="medium" />
                </IconButton>
              </Link>
            </Tooltip>
          )}
        </div>
      </div>
    ),
  });
  productsColumns.push({
    field: "title",
    headerName: "Title",
    width: 250,
    sortingOrder: ["desc", "asc"],
    renderCell: (_params: GridCellParams) => (
      <div className={"d-flex align-items-center"}>
        <img
          src={reduceImgSize(_params.row.imageURL) || _params.row.imageURL || defaultImage}
          onError={(e) => onImgErrorHandler(e, defaultImage)}
          alt={_params.row.productASIN}
          className={classes.Image}
        />
        <div>
          <div className={classes.CellLinesLimitContainer} title={_params.value}>
            {_params?.row?.status !== ContentStatusEnum.APPROVED &&
            _params?.row?.status !== ContentStatusEnum.PUBLISHED &&
            _params?.row?.editedGeneratedProductContent?.title
              ? _params?.row?.editedGeneratedProductContent.title
              : _params?.row?.title || " "}
          </div>

          <div className={"d-flex align-items-center gap-3"}>
            {_params?.row?.isProductLiveBeingChecked !== undefined && (
              <div className={"my-1"}>
                <ProductStatus
                  type={
                    _params?.row?.isProductLiveBeingChecked
                      ? ProductLiveStatusEnum.CHECKING
                      : _params?.row?.productLive?.isProductLive
                  }
                  variant={"small"}
                />
              </div>
            )}

            {!!getAllTagsOfProduct(tags, _params.row?._id)?.length && (
              <MuiTooltip
                arrow
                content={
                  <div className={"d-flex align-items-center p-2 flex-wrap gap-2"}>
                    {getAllTagsOfProduct(tags, _params.row?._id)?.map((tag) => (
                      <div
                        key={tag?._id}
                        style={{ backgroundColor: tag.color, color: "white" }}
                        className={`${classes.TagContainer}`}
                      >
                        {tag?.name}
                      </div>
                    ))}
                  </div>
                }
              >
                <div className={`d-flex align-items-center ${classes.Tag} cursor-pointer`}>
                  <LocalOfferOutlinedIcon fontSize={"small"} />
                  {getAllTagsOfProduct(tags, _params.row?._id)?.length}
                </div>
              </MuiTooltip>
            )}
          </div>
        </div>
      </div>
    ),
  });

  productsColumns.push({
    field: "searchIncrease",
    headerName: "Search Increase",
    width: 180,
    sortingOrder: ["desc", "asc"],
    renderCell: (_params: GridCellParams) => (
      <div className={`w-100 text-center ${classes.CellLinesLimitContainer}`}>
        {manageSearchIncrease(_params.row?.searchIncrease, _params.row?.volumeChange)}
      </div>
    ),
  });
  productsColumns.push({
    field: "volumeChange",
    headerName: "Volume Change",
    width: 180,
    sortingOrder: ["desc", "asc"],
    renderCell: (_params: GridCellParams) => (
      <div className={`w-100 text-center ${classes.CellLinesLimitContainer}`}>
        {manageVolumeChange(_params.row?.volumeChange)}
      </div>
    ),
  });
  productsColumns.push({
    field: "keywordCoverageRatio",
    headerName: "Keyword Coverage Ratio",
    width: 230,
    sortingOrder: ["desc", "asc"],
    renderCell: (_params: GridCellParams) => (
      <div className={`w-100 text-center ${classes.CellLinesLimitContainer}`}>
        {manageKeywordCoverageRatio(_params.row?.keywordCoverageRatio)}
      </div>
    ),
  });
  productsColumns.push({
    field: "lastLiveDate",
    headerName: "Last Content Live",
    width: 200,
    // sortable: false,
    sortingOrder: ["desc", "asc"],
    renderCell: (_params: GridCellParams) => (
      <div className={`d-flex align-items-center justify-content-center w-100 ${classes.CellLinesLimitContainer}`}>
        {manageLastContentLive(_params.row?.lastLiveDate) === ProductLiveStatusEnum.NEVER ? (
          <ProductStatus type={ProductLiveStatusEnum.NEVER} />
        ) : (
          manageLastContentLive(_params.row?.lastLiveDate)
        )}
        {/* {manageLastContentLive(_params.row?.lastLiveDate)} */}
      </div>
    ),
  });

  return (
    <div className={isChildComponent ? "" : `${classes.Container}`}>
      <div className={"d-flex justify-content-between align-items-center mb-3"}>
        <MTextField
          icon={"search"}
          position={"start"}
          onChange={handleChange}
          name={"customKeyword"}
          placeholder={
            showUserEmailInProductsPage(userRole as RolesEnum) || dbUser?.companyID
              ? "Search products by ASIN, Title or Email"
              : "Search products by ASIN or Title"
          }
          value={search}
          margin={"dense"}
          rootClass={classes.TextInput}
        />
        {showToolbarOptions ? (
          <div className={`${classes.ToolbarsContainer} d-flex align-items-center gap-3`}></div>
        ) : (
          <div className={`d-flex align-items-center justify-content-end ${state.loading ? "gap-4" : "gap-2"}`}>
            <div>
              <div
                className={`d-flex align-items-center gap-1 justify-content-between cursor-pointer ${
                  state.loading ? `pe-none ${classes.DisabledText}` : "pe-auto HoverEffectForButton"
                }`}
                onClick={handleFilterClick}
              >
                <FilterListIcon fontSize={"medium"} />
                <MTypography variant={"subtitle1"} color="inherit">
                  {"Filters"}
                </MTypography>
              </div>
              <OpportunityGridFilterMenu
                open={showFilterMenu}
                anchorEl={anchorEl}
                onClose={handleFilterClose}
                handleFilterChange={handleFilterChange}
                hideLastLiveProducts={opportunityFilters.lastLiveProductsFilter}
                hideKeywordCoverageExceed={opportunityFilters.keywordCoverageFilter}
                keywordExceedLimit={opportunityFilters.exceedLimitFilter}
                marks={filterMarks}
              />
            </div>
            <div
              className={`d-flex align-items-center gap-1 cursor-pointer ${
                state.loading || isExportButton(userRole, dbUser?.companyID, companyRole)
                  ? `pe-none ${classes.DisabledText}`
                  : "pe-auto HoverEffectForButton"
              }`}
              onClick={handleCsvExport}
            >
              {!csvLoading && <FileDownloadOutlinedIcon fontSize={"medium"} />}
              <MTypography variant={"subtitle1"} color="inherit">
                {csvLoading ? "Exporting..." : "Export"}
              </MTypography>
            </div>
          </div>
        )}
      </div>
      {state.loading ? (
        <GridSkeleton componentType={"opportunityReport"} />
      ) : (
        <ProductTable
          total={totalProducts}
          pageSize={pageSize}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
          setPageSize={setPageSize}
          columns={productsColumns}
          rows={products ?? []}
          initialState={{
            columns: {
              columnVisibilityModel: {
                userEmail: showUserEmailInProductsPage(dbUser?.role),
              },
            },
          }}
          sortModel={[
            {
              field: sortBy || gridSort.sortBy || "volumeChange",
              // sort: sortOrder ? (sortOrder === 1 ? ("asc" as any) : ("desc" as any)) : "asc",
              sort: sortOrder ? ((gridSort.sortOrder || sortOrder) === 1 ? ("asc" as any) : ("desc" as any)) : "asc",
            },
          ]}
          // onCellClick={handleCellClick}
          setSortModel={setSortModel}
          onSelectionModelChange={onSelectedRows}
          selectionModel={selectionModel}
          checkboxSelection={false}
          isRowSelectable={(params: any) =>
            params.row.status !== ContentStatusEnum.ERROR && params.row.status !== ContentStatusEnum.GENERATING
          }
        />
      )}

      <KeywordsPageDialog
        open={state.openDialogBox}
        onClose={() => {
          setState({ ...state, openDialogBox: false });
        }}
        title={generatingProcessTimeTenToFifteen}
      />
    </div>
  );
};

export default memo(OpportunityGrid);
