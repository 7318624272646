import { errorHandler } from "../../utils/helpers/apis";
import { generateProductApis } from "../../apis/generate-product";
import { UserApis } from "../../apis/user";
import { userRole } from "../../utils/helpers/priviligesChecks";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { CompanyApis } from "../../apis/companies";
import { formatBulletsPoints, removeComma } from "../../utils/helpers/strings";
import { ContentStatusEnum } from "../../utils/constants/enums";
import { toDateOnly } from "../../utils/helpers/date";
import { exportCsvType } from "../../components/mui/dialogebox/exportAsinsDialoge";

const getExcelSheetData = (products: any, bulletPointsFormat: any): any => {
  try {
    const result: any = [];
    products.forEach((item: any) => {
      const sheet = [["", "AFTER", "BEFORE"]];

      const bulletPointsToShow = item.bulletPointsByChat ? item.bulletPointsByChat : ["N/A"]
      
      const titleToShow = item?.title || "";

      const descriptionToShow = item?.status !== ContentStatusEnum.APPROVED && item?.status !== ContentStatusEnum.PUBLISHED && item?.editedGeneratedProductContent?.description ? item?.editedGeneratedProductContent?.description : item?.description

      sheet.push(["Title", titleToShow || "", item?.oldTitle || ""]);
      for (let i = 0; i < 5; i++) {
        sheet.push([
          `BP ${i + 1}`,
          formatBulletsPoints(bulletPointsToShow[i], bulletPointsFormat),
          item.hasOwnProperty("oldBulletPoints") ? item?.oldBulletPoints[i] : "",
        ]);
      }
      sheet.push(["Description", descriptionToShow, item?.oldDescription]);
      sheet.push(["KEYWORDS", item?.selectedHelium10Keywords.join("\n"), ""]);
      sheet.push(["COMPETITOR ASINs", item?.generatedContentCompetitors.join(", "), ""]);
      sheet.push(["Title Keywords", item?.titleKeywords.join(", "), ""]);
      sheet.push(["Rest of Keywords", item?.otherKeywords.join(", "), ""]);
      result.push(sheet);
    });

    return result;
  } catch (e) {
    console.log("error", e);
    return [];
  }
};

export const downloadSavedProductCsv = async ({
  showProtectedFields,
  brand,
  teamUserID,
  isCompany,
  companyID,
  filters
}: any) => {
  try {
    return await generateProductApis.getCsvProducts({ filters, brand, userID: teamUserID, isCompany, companyID, showProtectedFields });
  } catch (e) {
    throw new Error(errorHandler(e));
  }
};

export const downloadSelectedProductsCsv = async ({ payload, exportType, showProtectedFields, bulletPointsFormat }: any) => {
  try {
    let dataArray: any[] = [];
    let products = await generateProductApis.getSelectedProductsCsv(payload);

    if (exportType === exportCsvType.row) {
      dataArray = products.map((product) => {
        const protectedFields = showProtectedFields
          ? {
            "User Email": product.userEmail ? product.userEmail : "N/A",
            "User Name": product.userName ? product.userName : "N/A",
          }
          : {};
        
        const titleToShow = product?.title || "";
        
        const bulletPointsToShow = product.bulletPointsByChat ? product.bulletPointsByChat : ["N/A"];

        const descriptionToShow = product?.status !== ContentStatusEnum.APPROVED && product?.status !== ContentStatusEnum.PUBLISHED && product?.editedGeneratedProductContent?.description ? product?.editedGeneratedProductContent?.description : product?.description
        const productAfterSearchTermToShow = product?.status !== ContentStatusEnum.APPROVED && product?.status !== ContentStatusEnum.PUBLISHED && product?.editedGeneratedProductContent?.newProductContentScore?.cumulativeSearchVolume ? product?.editedGeneratedProductContent?.newProductContentScore?.cumulativeSearchVolume : product?.newProductContentScore?.cumulativeSearchVolume

        return {
          "Product ID (ASIN)": product.productASIN ? product.productASIN : "N/A",
          ...protectedFields,
          Brand: product.productBrand ? removeComma(product.productBrand) : "N/A",
          "Generated Title": titleToShow ? `"${titleToShow.replace(/"/g, '""')}"` : "N/A",
          "Generated Bullets": bulletPointsToShow ?
            `"${bulletPointsToShow
              .map((bullet: any) => formatBulletsPoints(bullet, bulletPointsFormat) || "")
              .toString()
              .replace(/"/g, '""')
            }"` : "N/A",
          "Generated Description": descriptionToShow ? `"${descriptionToShow.replace(/"/g, '""')}"` : "N/A",
          "Keyword List":
            product.selectedHelium10Keywords && product.selectedHelium10Keywords.length
              ? `"${product.selectedHelium10Keywords
                .toString()
                .replace(/"/g, '""')}"`
              : "N/A",
          "Keyword List Monthly After Search Term Volume": productAfterSearchTermToShow
            ? `"${productAfterSearchTermToShow}"`
            : 0,
          "Keyword List Monthly Before Search Term Volume": product?.existingProductContentScore?.cumulativeSearchVolume
            ? `"${product.existingProductContentScore.cumulativeSearchVolume}"`
            : 0,
          "Competitor List":
            product.generatedContentCompetitors && product.generatedContentCompetitors.length
              ? `"${product.generatedContentCompetitors
                .toString()
                .replace(/"/g, '""')}"`
              : "N/A",
          "Old Title": product.oldTitle ? `"${product.oldTitle.replace(/"/g, '""')}"` : "N/A",
          "Old Bullets":
            product.oldBulletPoints && product.oldBulletPoints.length
              ? `"${product.oldBulletPoints
                .map((bullet) => bullet || "")
                .toString()
                .replace(/"/g, '""')}"`
              : "N/A",
          "Old Description": product.oldDescription ? `"${product.oldDescription.replace(/"/g, '""')}"` : "N/A",
          "Generation Date": product.lastProductGeneratedAt ? toDateOnly(product.lastProductGeneratedAt) : "",
        };
      });
      const csvContent = [
        Object.keys(dataArray[0]).join(","), // Header row
        ...dataArray.map((item) => Object.values(item).join(",")), // Data rows
      ].join("\n");
      const BOM = "\uFEFF";
      const blob = new Blob([BOM + csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "products.csv";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      const workbook = XLSX.utils.book_new();

      const excelData = getExcelSheetData(products, bulletPointsFormat);
      if (excelData.length > 0) {
        excelData.forEach((product: any, index: number) => {
          const sheet = XLSX.utils.aoa_to_sheet(product);
          XLSX.utils.book_append_sheet(workbook, sheet, `${products[index]?.productASIN}`);
        });
      }

      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      const excelBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(excelBlob, "multi_sheet.xlsx");
    }
  } catch (e) {
    throw new Error(errorHandler(e));
  }
};

export const checkLiveProductLimit = 20;
// for regeneration limit check
export const checkIsProductOwnerReachedHisLimit = async (userID: string) => {
  return UserApis.getProductOwner(userID)
    .then((res: any) => {
      if (userRole.isAdminOrEditorOrInternalOrOwner(res?.role)) {
        return false;
      } else {
        return (
          res?.contentRegenerationLimit === res?.countContentRegeneration ||
          res?.helium10CallsLimit === res?.countHelium10Calls
        );
      }
    })
    .catch((error: any) => {
      console.log("error while fetching product owner", error);
    });
};

// for helium 10 limit check
export const checkIsUserReachedHisHelium10Limit = async (userID: string) => {
  return UserApis.getProductOwner(userID)
    .then((res: any) => {
      if (userRole.isAdminOrEditorOrInternalOrOwner(res?.role)) {
        return false;
      } else {
        return res?.helium10CallsLimit === res?.countHelium10Calls;
      }
    })
    .catch((error: any) => {
      console.log("error while fetching product owner", error);
    });
};

export const checkIsCompanyReachedHeliumOrContentRegenLimit = async (companyID: string) => {
  return CompanyApis.getCompany(companyID)
    .then((res: any) => {
      return (
        res?.contentRegenerationLimit === res?.countContentRegeneration ||
        res?.helium10CallsLimit === res?.countHelium10Calls
      );
    })
    .catch((error: any) => {
      console.log("error while fetching product owner", error);
    });
};

export const checkIsCompanyReachedHelium10Limit = async (companyID: string) => {
  return CompanyApis.getCompany(companyID)
    .then((res: any) => {
      return res?.helium10CallsLimit === res?.countHelium10Calls;
    })
    .catch((error: any) => {
      console.log("error while fetching product owner", error);
    });
};
