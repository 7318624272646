import React from "react";

import MCheckbox from "../../../../../Atoms/MCheckbox";

import classes from "./CheckboxFilters.module.css";

export const bsrPerformanceCheckBoxValues = {
  bsr:'bsr',
  conversationRate:"conversionRate",
  traffic:"sessions"
}

const checkBoxes: any = [
  { name: "BSR", value: bsrPerformanceCheckBoxValues.bsr, color: "#F94144" },
  { name: "Conversion Rate %", value: bsrPerformanceCheckBoxValues.conversationRate, color: "#4285F4" },
  { name: "Traffic", value: bsrPerformanceCheckBoxValues.traffic, color: "#90BE6D" },
  // { name: "Revenue", value: "revenue", color: "#D87B00" },
];

interface CheckboxFiltersProps {
  setCheckbox: any;
  selected: string[]
}

const CheckboxFilters: React.FC<CheckboxFiltersProps> = ({ setCheckbox, selected }) => {
  // const [selected, setSelected] = useState<string[]>([]);

  const isChecked = (value: string): boolean => {
    return selected.includes(value);
  };

  const handleCheckboxes = (value: string):any => {
    if(value === bsrPerformanceCheckBoxValues.bsr)
      return false
    if (isChecked(value)) {
      const filteredCheckboxes = selected.filter((item: string) => item !== value);
      setCheckbox(filteredCheckboxes);
    } else {
      if(selected.length >=2){
        return false
      }
      setCheckbox([...selected, value]);
    }
  };


  return (
    <div className={"d-flex gap-1 my-1"}>
      {checkBoxes.map((checkbox: any) => (
        <div key={checkbox.value} className={"d-flex align-items-center gap-1"}>
          <MCheckbox
            disabled={ selected.length === 2 && !selected.includes(checkbox.value)}
            variant={"square"}
            checked={isChecked(checkbox.value)}
            onClick={() => handleCheckboxes(checkbox.value)}
          />
          <div className={classes.Dot} style={{ backgroundColor: checkbox.color, opacity: selected.length === 2 && !selected.includes(checkbox.value) ? 0.5 : 1 }}></div>
          <div className={classes.Text} style={{  opacity: selected.length === 2 && !selected.includes(checkbox.value) ? 0.5 : 1 }}>{checkbox.name}</div>
        </div>
      ))}
    </div>
  );
};

export default CheckboxFilters;
