import { fetchAmazonASIN } from "../../../../utils/helpers/regex";
import * as XLSX from "xlsx";

export const bulkAsinsLimitUsingFile = 500;

export const instructionsList = [
  "You can upload only XLS or XLSX file.",
  "File must have ASINS column.",
  "File size should not exceed 10MB.",
  "You can download sample file.",
  `Maximum upload limit is ${bulkAsinsLimitUsingFile} ASINs.`,
];

export const handleDownload = (fileType: string) => {
  const sampleData = [
    [
      "Product ID(ASIN)",
      "Attribute 1",
      "Attribute 2",
      "Attribute 3",
      "Title Instructions",
      "Note"
    ],
    [
      "B00065V000",
      "Yankees",
      "Cats",
      "Black",
      "Include the brand name product type key features size or quantity",
      "Please note that the instructions provided for the title will be applied exclusively to the specified ASIN."
    ],
    [
      "B000YZ1001",
      "Devil Rays",
      "House",
      "30 feet",
      "Do not use generic words special characters and restricted keywords",
      ""
    ],
    ["B01IU6I002", "", "", "", "", ""],
    ["B0BNJG7003", "", "", "", "", ""],
    ["B0013J6004", "", "", "", "", ""],
    ["B06XV21005", "", "", "", "", ""],
    ["B0086A7006", "", "", "", "", ""],
    ["B000BXO007", "", "", "", "", ""],
    ["B004H37008", "", "", "", "", ""],
    ["B007QU2009", "", "", "", "", ""]
  ];

   if (fileType === "csv") {
    const csvContent = sampleData.map(row => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Csv_Sample.csv";
    link.click();
  } else {
    const worksheet = XLSX.utils.aoa_to_sheet(sampleData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, "Xls_Sample.xls", { bookType: "xls" });
  }
};

export const arrayContainsAllUndefined = (arr: any[]) => {
  return arr.every((element) => element === undefined);
};

const arrangeDataOfImportedSheet = (dataArray: string[]) => {
  // Remove the header line
  const rows = dataArray.slice(1);

  // Function to parse each row and create objects
  return rows.map(row => {
    const columns = row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/).map(col => col.trim());

    // Extract relevant data based on position
    const asin = columns[0];
    let attribute1 = columns[1] || "";
    let attribute2 = columns[2] || "";
    let attribute3 = columns[3] || "";
    let instructions = columns[4] || "";
    
    // removing / aaded for commas values
    attribute1 = attribute1?.replace(/\\/g, '')?.replace(/^"(.*)"$/, '$1');
    attribute2 = attribute2?.replace(/\\/g, '')?.replace(/^"(.*)"$/, '$1');
    attribute3 = attribute3?.replace(/\\/g, '')?.replace(/^"(.*)"$/, '$1');
    instructions = instructions?.replace(/\\/g, '')?.replace(/^"(.*)"$/, '$1');

    const attributes = [attribute1, attribute2, attribute3]?.filter(attr => attr)?.join("|||");

    const validAsin: string | false = fetchAmazonASIN(asin);
    if (validAsin && validAsin.length === 10 && validAsin.substring(0, 2) === "B0") {
      return {
        productASIN: asin,
        attributes: attributes,
        titleInstruction: instructions
      };
    } else {
      return null;
    }
  })?.filter(entry => entry !== null);
};

const removeDuplicatesAsins = (array: any) => {
  const unique = [];
  const seenAsins = new Set();

  for (const item of array) {
    if (!seenAsins.has(item.productASIN)) {
      seenAsins.add(item.productASIN);
      unique.push(item);
    }
  }

  return unique;
};

export const parseFile = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const data = event?.target?.result;

      if (file.name.endsWith(".csv")) {
        if (typeof data === "string") {
          const rows = data.split(/\r?\n/);
          const arrangedData = arrangeDataOfImportedSheet(rows)
          const filteredList = removeDuplicatesAsins(arrangedData)
          resolve(filteredList);
        }
      } else if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const sheettocsv = XLSX.utils.sheet_to_csv(sheet);
        const rows = sheettocsv.split(/\r?\n/);
        const arrangedData = arrangeDataOfImportedSheet(rows)
        const filteredList = removeDuplicatesAsins(arrangedData)
        // const filteredList = generateArray(rows);
        resolve(filteredList);
      } else {
        reject(new Error("Unsupported file format"));
      }
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsBinaryString(file);
  });
};

export function isArrayOfString(value: any): value is string[] {
  return Array.isArray(value) && value.every((item) => typeof item === "string");
}
