import moment from "moment/moment";

export const bsrChartType = {
  searchVolume: "BSR vs Search Exposure",
  performance: "BSR Performance Over Time",
  bsrAverage: "BSR over Average Competitor",
  eventCalendar: "Events Calendar",
};

export const bsrPerformanceAnnotation: any = {
  partially_live: {
    x: moment("2023-11-10T13:07:05.637Z").format("DD MMM"),
    strokeDashArray: 2,
    borderColor: "#6AC9F2",
    label: {
      borderColor: "#6AC9F2",
      position: "top",
      style: {
        color: "#fff",
        background: "#6AC9F2",
        padding: {
          left: 4,
          right: 4,
          top: 4,
          bottom: 0,
        },
      },
      text: "Partial Live",
    },
  },
  live: {
    x: "16 Nov",
    strokeDashArray: 2,
    borderColor: "#0CC683",
    label: {
      borderColor: "#0CC683",
      position: "top",
      style: {
        color: "#fff",
        background: "#0CC683",
        padding: {
          left: 4,
          right: 4,
          top: 4,
          bottom: 0,
        },
      },
      text: "Content Live",
    },
  },
  inactive: {
    x: "16 Nov",
    strokeDashArray: 2,
    borderColor: "#FF4C05",
    label: {
      borderColor: "#FF4C05",
      position: "top",
      style: {
        color: "#fff",
        background: "#FF4C05",
        padding: {
          left: 4,
          right: 4,
          top: 4,
          bottom: 0,
        },
      },
      text: "Inactive",
    },
  },
  deactivated: {
    x: "16 Nov",
    strokeDashArray: 2,
    borderColor: "#FF4C05",
    label: {
      borderColor: "#FF4C05",
      position: "top",
      style: {
        color: "#fff",
        background: "#FF4C05",
        padding: {
          left: 4,
          right: 4,
          top: 4,
          bottom: 0,
        },
      },
      text: "Deactivated",
    },
  },
};

export const getBsPerformanceAnnotations = (data: any): any => {
  try {
    const result: any = [];
    if (data.length > 0) {
      data.forEach((item: any) => {
        // if (item.isProductLive !== "inactive") {
        result.push({
          ...bsrPerformanceAnnotation[item.isProductLive],
          x: moment(item?.runDate).format("DD MMM"),
        });
        // }
      });
    }
    return result;
  } catch (e) {
    console.log("error getBsPerformanceAnnotations", e);
    return [];
  }
};

export const bsrPerformanceInitialValue = {
  line: [],
  yAxis: [],
  xAxis: ["21 Nov", "22 Nov", "23 Nov", "24 Nov", "25 Nov", "26 Nov", "27 Nov", "28 Nov", "29 Nov", "30 Nov"],
};

export const checkBoxesForBsrAverage = [
  { name: "Average Competitor BSR", value: "Average Competitor BSR", color: "#9747FF" },
];
export function generateSortedRandomNumbers(arr: any): number[] {
  try {
    let combinedArray: number[] = [];
    arr.forEach((obj: any) => {
      combinedArray = combinedArray.concat(obj.data);
    });

    combinedArray.sort((a, b) => a - b);
    if (combinedArray.length === 0) {
      throw new Error("The combined data array must contain at least 1 elements.");
    } else if (combinedArray.length < 5) {
      const remainigLength = 5 - combinedArray.length;
      const newArr = [];
      if (remainigLength > 0) {
        for (let i = 1; i <= remainigLength; i++) {
          newArr.push(0);
        }
      }
      return [...newArr, ...combinedArray];
    }

    const smallest = combinedArray[0];
    const largest = combinedArray[combinedArray.length - 1];

    let middleIndices: number[] = [];
    while (middleIndices.length < 3) {
      const randIndex = Math.floor(Math.random() * (combinedArray.length - 2)) + 1;
      if (!middleIndices.includes(randIndex)) {
        middleIndices.push(randIndex);
      }
    }

    let middleElements: number[] = middleIndices.map((index) => combinedArray[index]);

    let finalArray: number[] = [smallest, ...middleElements, largest];

    finalArray.sort((a, b) => a - b);

    return finalArray;
  } catch (e) {
    console.log("test pick issue error", e);
    return [];
  }
}

export function generateSequentialNumbers(smallest: number, largest: number): number[] {
  if (smallest >= largest) {
    throw new Error("The smallest number must be less than the largest number.");
  }

  const step = (largest - smallest) / 4;
  const numbers: number[] = [];

  for (let i = 0; i <= 4; i++) {
    const num = smallest + i * step;
    if (num !== 0) {
      numbers.push(Math.ceil(num));
    }
  }

  return numbers;
}
export const removeZeroArray = (chartData: any): any => {
  let data: any = [];
  chartData.forEach((item: any) => {
    if (parseInt(item.data.join("")) !== 0) {
      data.push(item);
    }
  });
  return data;
};

export const generateBsrAverageData = ({ chartData, averageCheckbox, asin }: any): any => {
  try {
    let data: any = [];
    // const data = averageCheckbox ? chartData : chartData.filter((item: any) => !item.name.includes('Average'));

    if (averageCheckbox) {
      chartData.forEach((item: any) => {
        if (item.name.includes("Average")) {
          data.push({
            ...item,
            data: item.data.map((rank: any) => (rank === 0 ? null : rank)) || [],
          });
        } else if (item.name === asin) {
          data.push({
            ...item,
            data: item.data.map((rank: any) => (rank === 0 ? null : rank)) || [],
          });
        }
      });
    } else {
      chartData.forEach((item: any) => {
        if (!item.name.includes("Average")) {
          data.push({
            ...item,
            data: item.data.map((rank: any) => (rank === 0 ? null : rank)) || [],
          });
        }
      });
    }
    // const result = data.map((item: any) => {
    //   return {
    //     name: item?.name,
    //     data: item.data.map((rank: any) => (rank === 0 ? 0 : 1 / rank)) || [],
    //   };
    // });
    return data;
  } catch (e) {
    return [];
  }
};

export const generateBsrAverageDataForTooltip = ({ chartData, averageCheckbox, asin }: any): any => {
  try {
    let data: any = [];
    // const data = averageCheckbox ? chartData : chartData.filter((item: any) => !item.name.includes('Average'));

    if (averageCheckbox) {
      chartData.forEach((item: any) => {
        if (item.name.includes("Average")) {
          data.push({
            ...item,
          });
        } else if (item.name === asin) {
          data.push({
            ...item,
          });
        }
      });
    } else {
      chartData.forEach((item: any) => {
        if (!item.name.includes("Average")) {
          data.push({
            ...item,
          });
        }
      });
    }
    // const result = data.map((item: any) => {
    //   return {
    //     name: item?.name,
    //     data: item.data.map((rank: any) => (rank === 0 ? 0 : 1 / rank)) || [],
    //   };
    // });
    return data;
  } catch (e) {
    return [];
  }
};
